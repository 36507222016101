const states = [
  { t: 'Baden-Württemberg', c: 1 },
  { t: 'Bayern', c: 1 },
  { t: 'Berlin', c: 1 },
  { t: 'Brandenburg', c: 1 },
  { t: 'Bremen', c: 1 },
  { t: 'Hamburg', c: 1 },
  { t: 'Hessen', c: 1 },
  { t: 'Mecklenburg-Vorpommern', c: 1 },
  { t: 'Niedersachsen', c: 1 },
  { t: 'Nordrhein-Westfalen', c: 1 },
  { t: 'Rheinland-Pfalz', c: 1 },
  { t: 'Saarland', c: 1 },
  { t: 'Sachsen', c: 1 },
  { t: 'Sachsen-Anhalt', c: 1 },
  { t: 'Schleswig-Holstein', c: 1 },
  { t: 'Thüringen', c: 1 }/*,
  { t: 'Burgenland', c: 2 },
  { t: 'Kärnten', c: 2 },
  { t: 'Niederösterreich', c: 2 },
  { t: 'Oberösterreich', c: 2 },
  { t: 'Salzburg', c: 2 },
  { t: 'Steiermark', c: 2 },
  { t: 'Tirol', c: 2 },
  { t: 'Vorarlberg', c: 2 },
  { t: 'Wien', c: 2 }*/
];

export default states;
