<template>
  <ModalYesNoGeneric :id="name" :title="title"
      :btnTextReset="btnTextReset" :btnTextSubmit="btnTextSubmit" :btnDisableSubmit="!val" :btnVariantSubmit="btnVariantSubmit"
      @submit="onSubmit" @reset="onReset" @shown="onShown"
      :ref="name" :hidePic="hidePic">
    <l-map ref="map" :zoom="defzoom" :center="center" @click="addMarker" @contextmenu="delMarker" class="mapheight">
      <l-tile-layer :url="url" :attribution="attribution" />
      <l-marker v-for="(m, index) in marker" :lat-lng="m" :key="index">
        <l-icon :icon-url="require(`@/assets/markergreen.svg`)" />
        <l-popup><h6>{{ $t('dial.mapyou') }}</h6></l-popup>
      </l-marker>
    </l-map>
  </ModalYesNoGeneric>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPopup, LIcon } from 'vue2-leaflet/dist/vue2-leaflet.min.js';
import ModalYesNoGeneric from '@/components/ModalYesNoGeneric';

export default {
  data() {
    return {
      show: false,
      defzoom:   14,
      url:      'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      attribution:'&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
      marker:   [],
    }
  },
  computed: {
    val() {
      return this.marker.length != 0;
    },
  },
  methods: {
    onShown() {
      this.$refs.map.mapObject.invalidateSize(); // refresh grey tiles
    },
    onReset() {
      this.delMarker();
      this.$emit('reset');
    },
    onSubmit() {
      if (this.val) {
        this.$emit('submit', [this.marker[0].lat, this.marker[0].lng]);
      }
    },
    addMarker(e) {
      if (this.marker !== []) {
        this.onReset();
      }
      this.marker.push(e.latlng);
    },
    delMarker() {
      this.marker = [];
    },
  },
  props: {
    center: {
      type: Array,
      default() {
        return [52.515220, 13.419482];
      }
    },
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    btnTextReset: {
      type: String,
      default: 'Reset',
    },
    btnTextSubmit: {
      type: String,
      required: true,
    },
    btnVariantSubmit: {
      type: String,
      default: 'danger',
    },
    hidePic: Boolean,
  },
  components: {
    ModalYesNoGeneric,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LIcon,
  }
}
</script>

<style scoped>
.mapheight {
  /* width: 66vw; */
  height: 66vh;
}
</style>