/* eslint-disable no-prototype-builtins */
// https://stackoverflow.com/questions/1068834/object-comparison-in-javascript
export function equalObjects(o1, o2) {
  for (var p in o1) {
      if (o1.hasOwnProperty(p)) {
          if(o1[p] !== o2[p]) {
              return false;
          }
      }
  }
  for (var x in o2) {
      if (o2.hasOwnProperty(x)) {
          if (o1[x] !== o2[x]) {
              return false;
          }
      }
  }
  return true;
}
