<template>
  <div v-if="!isUser">
    <b-form @submit.prevent="onSubmit" @reset.prevent="onReset" id="accRestForm">
      <h4>{{ $t('acc.hpvi') }}</h4>
      <b-form-group :label="$t('addr.locnl')" label-for="accRestName">
        <b-input type="text" id="accRestName" v-model="f.name" :state="valTxt(f.name, 2, 75)" maxlength="75" required trim />
        <b-form-invalid-feedback :state="valTxt(f.name, 2, 75)">
          {{ $t('addr.ff') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <AddressContent idPrefix="accRest" @status="getAddrStatus" disabled form="accRestForm"
          :aCity="f.city" @aCity="f.city = $event"
          :aStreet="f.street" @aStreet="f.street = $event"
          :aNumber="f.number" @aNumber="f.number = $event"
          :aZip="f.zip" @aZip="f.zip = $event"
          :aCountryId="f.countryId" @aCountryId="f.countryId = $event"
          :aStateId="f.stateId" @aStateId="f.stateId = $event" />
      <b-form-group :label="$t('acc.pel')" label-for="accRestEmail">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-at />
            </b-input-group-text>
          </template>
          <b-input type="email" id="accRestEmail" v-model="f.publicEmail" :state="valEmail" maxlength="50" trim />
        </b-input-group>
      </b-form-group>
      <b-form-group :label="$t('acc.ppl')" label-for="accRestTel">
        <b-input-group>
          <template #prepend>
            <b-input-group-text>
              <b-icon-phone />
            </b-input-group-text>
          </template>
          <b-input type="tel" id="accRestTel" v-model="f.publicPhone" :state="valPhone" pattern="^\+\d{1,3} [1-9]{1}\d{1,4} \d{3,10}$" maxlength="50" trim />
        </b-input-group>
      </b-form-group>

      <h4 class="pt-3">
        {{ $t('acc.hgl') }}
      </h4>
      <b-row class="py-0">
        <b-form-group :label="$t('acc.latl')" label-for="accRestLat" class="col">
          <b-input type="text" id="accRestLat" v-model="f.geoLat" :step="0.000001" :state="valFloat(f.geoLat)" number :formatter="commaRepl" />
        </b-form-group>
        <b-form-group :label="$t('acc.lonl')" label-for="accRestLong" class="col">
          <b-input type="text" id="accRestLong" v-model="f.geoLong" :step="0.000001" :state="valFloat(f.geoLong)" number :formatter="commaRepl" />
        </b-form-group>
        <b-form-group :label="$t('acc.bmap')" label-for="accRestGeoSea" class="col-auto">
          <b-button variant="primary" id="accRestGeoSea" block v-b-modal.custMap><Icon type="sea" /> {{ $t('btn.sea') }}</b-button>
        </b-form-group>
      </b-row>

      <h4 class="pt-3">
        {{ $t('acc.hoh') }}
      </h4>
      <b-row class="py-0">
        <b-col cols="12">
          <b-form-group :label="$t('acc.tzl')" label-for="accRestTimezone">
            <b-input-group>
              <template #prepend>
                <b-input-group-text>
                  <b-icon-clock />
                </b-input-group-text>
              </template>
              <b-form-select id="accRestTimezone" v-model="f.locationTimezone" :options="tList" text-field="t" value-field="v">
                <template #first>
                  <option :value="null" disabled>- {{ $t('dial.selopt') }} -</option>
                </template>
              </b-form-select>
            </b-input-group>
            <b-form-valid-feedback :state="f.locationTimezone!=tz">
              {{ $t('acc.ftw') }}
            </b-form-valid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="py-0">
        <b-col cols="6" md="7">
          <b-form-group class="align-items-center" :label="$t('acc.mon')" label-for="accRestMoS" label-cols-md="5" label-class="py-0">
            <TimePicker id="accRestMoS" v-model="cWhMoS" :state="valTimeComboS(f.whMoS, f.whMoE, f.whSuS, f.whSuE)" required hideSeconds :format="getTimeFormat" formid="accRestForm" />
            <b-form-invalid-feedback>
              <p class="mb-1" v-if="isEmpty(f.whMoS, f.whMoE)">{{ $t('acc.few') }}</p>
              <p class="mb-1" v-if="isWrongDay(f.whSuS, f.whSuE, f.whMoS)">{{ $t('acc.fdw') }}</p>
              <p class="mb-1" v-if="isTooLong(f.whMoS, f.whMoE)">{{ $t('acc.fxw') }}</p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="6" md="5">
          <b-form-group class="align-items-center" :label="$t('acc.to')" label-for="accRestMoE" label-cols-md="2" label-class="py-0">
            <TimePicker id="accRestMoE" v-model="cWhMoE" :state="valTimeComboE(f.whMoE, f.whMoS)" required hideSeconds :format="getTimeFormat" formid="accRestForm" />
            <b-form-invalid-feedback>
              <p class="mb-1" v-if="isEmpty(f.whMoS, f.whMoE)">{{ $t('acc.few') }}</p>
            </b-form-invalid-feedback>
            <b-form-valid-feedback>
              <p class="mb-1" v-if="isMidNight(f.whMoS, f.whMoE)">{{ $t('acc.fmw') }}</p>
            </b-form-valid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="py-0">
        <b-col cols="6" md="7">
          <b-form-group class="align-items-center" :label="$t('acc.tue')" label-for="accRestTuS" label-cols-md="5" label-class="py-0">
            <TimePicker id="accRestTuS" v-model="cWhTuS" :state="valTimeComboS(f.whTuS, f.whTuE, f.whMoS, f.whMoE)" required hideSeconds :format="getTimeFormat" formid="accRestForm" />
            <b-form-invalid-feedback>
              <p class="mb-1" v-if="isEmpty(f.whTuS, f.whTuE)">{{ $t('acc.few') }}</p>
              <p class="mb-1" v-if="isWrongDay(f.whMoS, f.whMoE, f.whTuS)">{{ $t('acc.fdw') }}</p>
              <p class="mb-1" v-if="isTooLong(f.whTuS, f.whTuE)">{{ $t('acc.fxw') }}</p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="6" md="5">
          <b-form-group class="align-items-center" :label="$t('acc.to')" label-for="accRestTuE" label-cols-md="2" label-class="py-0">
            <TimePicker id="accRestTuE" v-model="cWhTuE" :state="valTimeComboE(f.whTuE, f.whTuS)" required hideSeconds :format="getTimeFormat" formid="accRestForm" />
            <b-form-invalid-feedback>
              <p class="mb-1" v-if="isEmpty(f.whTuS, f.whTuE)">{{ $t('acc.few') }}</p>
            </b-form-invalid-feedback>
            <b-form-valid-feedback>
              <p class="mb-1" v-if="isMidNight(f.whTuS, f.whTuE)">{{ $t('acc.fmw') }}</p>
            </b-form-valid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="py-0">
        <b-col cols="6" md="7">
          <b-form-group class="align-items-center" :label="$t('acc.wed')" label-for="accRestWeS" label-cols-md="5" label-class="py-0">
            <TimePicker id="accRestWeS" v-model="cWhWeS" :state="valTimeComboS(f.whWeS, f.whWeE, f.whTuS, f.whTuE)" required hideSeconds :format="getTimeFormat" formid="accRestForm" />
            <b-form-invalid-feedback>
              <p class="mb-1" v-if="isEmpty(f.whWeS, f.whWeE)">{{ $t('acc.few') }}</p>
              <p class="mb-1" v-if="isWrongDay(f.whTuS, f.whTuE, f.whWeS)">{{ $t('acc.fdw') }}</p>
              <p class="mb-1" v-if="isTooLong(f.whWeS, f.whWeE)">{{ $t('acc.fxw') }}</p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="6" md="5">
          <b-form-group class="align-items-center" :label="$t('acc.to')" label-for="accRestWeE" label-cols-md="2" label-class="py-0">
            <TimePicker id="accRestWeE" v-model="cWhWeE" :state="valTimeComboE(f.whWeE, f.whWeS)" required hideSeconds :format="getTimeFormat" formid="accRestForm" />
            <b-form-invalid-feedback>
              <p class="mb-1" v-if="isEmpty(f.whWeS, f.whWeE)">{{ $t('acc.few') }}</p>
            </b-form-invalid-feedback>
            <b-form-valid-feedback>
              <p class="mb-1" v-if="isMidNight(f.whWeS, f.whWeE)">{{ $t('acc.fmw') }}</p>
            </b-form-valid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="py-0">
        <b-col cols="6" md="7">
          <b-form-group class="align-items-center" :label="$t('acc.thu')" label-for="accRestThS" label-cols-md="5" label-class="py-0">
            <TimePicker id="accRestThS" v-model="cWhThS" :state="valTimeComboS(f.whThS, f.whThE, f.whWeS, f.whWeE)" required hideSeconds :format="getTimeFormat" formid="accRestForm" />
            <b-form-invalid-feedback>
              <p class="mb-1" v-if="isEmpty(f.whThS, f.whThE)">{{ $t('acc.few') }}</p>
              <p class="mb-1" v-if="isWrongDay(f.whWeS, f.whWeE, f.whThS)">{{ $t('acc.fdw') }}</p>
              <p class="mb-1" v-if="isTooLong(f.whThS, f.whThE)">{{ $t('acc.fxw') }}</p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="6" md="5">
          <b-form-group class="align-items-center" :label="$t('acc.to')" label-for="accRestThE" label-cols-md="2" label-class="py-0">
            <TimePicker id="accRestThE" v-model="cWhThE" :state="valTimeComboE(f.whThE, f.whThS)" required hideSeconds :format="getTimeFormat" formid="accRestForm" />
            <b-form-invalid-feedback>
              <p class="mb-1" v-if="isEmpty(f.whThS, f.whThE)">{{ $t('acc.few') }}</p>
            </b-form-invalid-feedback>
            <b-form-valid-feedback>
              <p class="mb-1" v-if="isMidNight(f.whThS, f.whThE)">{{ $t('acc.fmw') }}</p>
            </b-form-valid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="py-0">
        <b-col cols="6" md="7">
          <b-form-group class="align-items-center" :label="$t('acc.fri')" label-for="accRestFrS" label-cols-md="5" label-class="py-0">
            <TimePicker id="accRestFrS" v-model="cWhFrS" :state="valTimeComboS(f.whFrS, f.whFrE, f.whThS, f.whThE)" required hideSeconds :format="getTimeFormat" formid="accRestForm" />
            <b-form-invalid-feedback>
              <p class="mb-1" v-if="isEmpty(f.whFrS, f.whFrE)">{{ $t('acc.few') }}</p>
              <p class="mb-1" v-if="isWrongDay(f.whThS, f.whThE, f.whFrS)">{{ $t('acc.fdw') }}</p>
              <p class="mb-1" v-if="isTooLong(f.whFrS, f.whFrE)">{{ $t('acc.fxw') }}</p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="6" md="5">
          <b-form-group class="align-items-center" :label="$t('acc.to')" label-for="accRestFrE" label-cols-md="2" label-class="py-0">
            <TimePicker id="accRestFrE" v-model="cWhFrE" :state="valTimeComboE(f.whFrE, f.whFrS)" required hideSeconds :format="getTimeFormat" formid="accRestForm" />
            <b-form-invalid-feedback>
              <p class="mb-1" v-if="isEmpty(f.whFrS, f.whFrE)">{{ $t('acc.few') }}</p>
            </b-form-invalid-feedback>
            <b-form-valid-feedback>
              <p class="mb-1" v-if="isMidNight(f.whFrS, f.whFrE)">{{ $t('acc.fmw') }}</p>
            </b-form-valid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="py-0">
        <b-col cols="6" md="7">
          <b-form-group class="align-items-center" :label="$t('acc.sat')" label-for="accRestSaS" label-cols-md="5" label-class="py-0">
            <TimePicker id="accRestSaS" v-model="cWhSaS" :state="valTimeComboS(f.whSaS, f.whSaE, f.whFrS, f.whFrE)" required hideSeconds :format="getTimeFormat" formid="accRestForm" />
            <b-form-invalid-feedback>
              <p class="mb-1" v-if="isEmpty(f.whSaS, f.whSaE)">{{ $t('acc.few') }}</p>
              <p class="mb-1" v-if="isWrongDay(f.whFrS, f.whFrE, f.whSaS)">{{ $t('acc.fdw') }}</p>
              <p class="mb-1" v-if="isTooLong(f.whSaS, f.whSaE)">{{ $t('acc.fxw') }}</p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="6" md="5">
          <b-form-group class="align-items-center" :label="$t('acc.to')" label-for="accRestSaE" label-cols-md="2" label-class="py-0">
            <TimePicker id="accRestSaE" v-model="cWhSaE" :state="valTimeComboE(f.whSaE, f.whSaS)" required hideSeconds :format="getTimeFormat" formid="accRestForm" />
            <b-form-invalid-feedback>
              <p class="mb-1" v-if="isEmpty(f.whSaS, f.whSaE)">{{ $t('acc.few') }}</p>
            </b-form-invalid-feedback>
            <b-form-valid-feedback>
              <p class="mb-1" v-if="isMidNight(f.whSaS, f.whSaE)">{{ $t('acc.fmw') }}</p>
            </b-form-valid-feedback>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row class="py-0">
        <b-col cols="6" md="7">
          <b-form-group class="align-items-center" :label="$t('acc.sun')" label-for="accRestSuS" label-cols-md="5" label-class="py-0">
            <TimePicker id="accRestSuS" v-model="cWhSuS" :state="valTimeComboS(f.whSuS, f.whSuE, f.whSaS, f.whSaE)" required hideSeconds :format="getTimeFormat" formid="accRestForm" />
            <b-form-invalid-feedback>
              <p class="mb-1" v-if="isEmpty(f.whSuS, f.whSuE)">{{ $t('acc.few') }}</p>
              <p class="mb-1" v-if="isWrongDay(f.whSaS, f.whSaE, f.whSuS)">{{ $t('acc.fdw') }}</p>
              <p class="mb-1" v-if="isTooLong(f.whMoS, f.whMoE)">{{ $t('acc.fxw') }}</p>
            </b-form-invalid-feedback>
          </b-form-group>
        </b-col>
        <b-col cols="6" md="5">
          <b-form-group class="align-items-center" :label="$t('acc.to')" label-for="accRestSuE" label-cols-md="2" label-class="py-0">
            <TimePicker id="accRestSuE" v-model="cWhSuE" :state="valTimeComboE(f.whSuE, f.whSuS)" required hideSeconds :format="getTimeFormat" formid="accRestForm" />
            <b-form-invalid-feedback>
              <p class="mb-1" v-if="isEmpty(f.whSuS, f.whSuE)">{{ $t('acc.few') }}</p>
            </b-form-invalid-feedback>
            <b-form-valid-feedback>
              <p class="mb-1" v-if="isMidNight(f.whSuS, f.whSuE)">{{ $t('acc.fmw') }}</p>
            </b-form-valid-feedback>
          </b-form-group>
        </b-col>
      </b-row>

      <b-form-row>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button type="reset" variant="primary" class="mr-2 mr-sm-3" id="accRestBtnReset" :disabled="!isChanged"><Icon type="r" /> {{ $t('btn.r') }}</b-button>
          <b-button type="submit" variant="primary" id="accRestBtnSubmit" :disabled="!val || !isChanged"><Icon type="u" /> {{ $t('btn.u') }}</b-button>
        </b-col>
      </b-form-row>
    </b-form>

    <ModalMap @submit="onMapSelect" name="custMap" :title="$t('acc.tmap')"
        :btnTextReset="$t('btn.r')" :btnTextSubmit="$t('btn.p')" btnVariantSubmit="primary" hidePic />
  </div>
</template>

<script>
import Icon from '@/components/Icon';
import { BIconAt, BIconClock, BIconPhone } from 'bootstrap-vue';
import { REST } from '@/components/RestCall';
import TimePicker from '@/components/TimePicker';
import tzList from '@/components/data/Timezones';
import ModalMap from '@/components/shared/ModalMap';
import AddressContent from '@/components/shared/AddressContent';
import { equalObjects } from '@/components/scripts/EqualObjects';

export default {
  data() {
    return {
      f: {
        uuid4: '',
        name: '',
        street: '',
        number: '',
        city: '',
        zip: '',
        countryId: 1,
        stateId: null,
        publicEmail: null,
        publicPhone: null,
        geoLong: null,
        geoLat: null,
        locationTimezone: 'Europe/Berlin',
        whMoS: null,
        whMoE: null,
        whTuS: null,
        whTuE: null,
        whWeS: null,
        whWeE: null,
        whThS: null,
        whThE: null,
        whFrS: null,
        whFrE: null,
        whSaS: null,
        whSaE: null,
        whSuS: null,
        whSuE: null
      },
      isAddrSuccess: false,
    }
  },
  computed: {
    tList() {
      return tzList;
    },
    tz() {
      return this.$store.state.settings.timezone;
    },
    onlineData() {
      return this.$store.state.oLocation;
    },
    isUser() {
      return this.$store.state.oLogin.isUser;
    },
    valAddr() {
      return this.isAddrSuccess;
    },
    valEmail() {
      if (this.f.publicEmail === null) return null;
      let rExp = /(.+)@(.+){2,}\.(.+){2,}/;
      return rExp.test(this.f.publicEmail.toLowerCase());
    },
    valPhone() {
      if (this.f.publicPhone === null || this.f.publicPhone === '') return null;
      return this.f.publicPhone.match(/^\+\d{1,3} [1-9]{1}\d{1,4} \d{3,10}$/gi) != null;
    },
    val() {
      return (this.valTxt(this.f.name, 2, 75) && this.valAddr === true &&
              this.valPhone !== false && this.valEmail !== false && this.f.locationTimezone !== null &&
              this.valFloat(this.f.geoLat) !== false && this.valFloat(this.f.geoLong) !== false &&
              this.valTimeComboS(this.f.whMoS,this.f.whMoE,this.f.whSuS,this.f.whSuE)!==false && this.valTimeComboE(this.f.whMoE,this.f.whMoS)!==false &&
              this.valTimeComboS(this.f.whTuS,this.f.whTuE,this.f.whMoS,this.f.whMoE)!==false && this.valTimeComboE(this.f.whTuE,this.f.whTuS)!==false &&
              this.valTimeComboS(this.f.whWeS,this.f.whWeE,this.f.whTuS,this.f.whTuE)!==false && this.valTimeComboE(this.f.whWeE,this.f.whWeS)!==false &&
              this.valTimeComboS(this.f.whThS,this.f.whThE,this.f.whWeS,this.f.whWeE)!==false && this.valTimeComboE(this.f.whThE,this.f.whThS)!==false &&
              this.valTimeComboS(this.f.whFrS,this.f.whFrE,this.f.whThS,this.f.whThE)!==false && this.valTimeComboE(this.f.whFrE,this.f.whFrS)!==false &&
              this.valTimeComboS(this.f.whSaS,this.f.whSaE,this.f.whFrS,this.f.whFrE)!==false && this.valTimeComboE(this.f.whSaE,this.f.whSaS)!==false &&
              this.valTimeComboS(this.f.whSuS,this.f.whSuE,this.f.whSaS,this.f.whSaE)!==false && this.valTimeComboE(this.f.whSuE,this.f.whSuS)!==false
              );
    },
    getTimeFormat() {
      return this.$i18n.getDateTimeFormat(this.$i18n.locale).t;
    },
    isChanged() {
      return !equalObjects(this.$store.state.oLocation, this.f);
    },
    cWhMoS: {
      get() { return this.getTimeStr(this.f.whMoS); },
      set(v) { this.setTimeVal('whMoS', this.f.whMoS, v); }
    },
    cWhMoE: {
      get() { return this.getTimeStr(this.f.whMoE); },
      set(v) { this.setTimeVal('whMoE', this.f.whMoE, v); }
    },
    cWhTuS: {
      get() { return this.getTimeStr(this.f.whTuS); },
      set(v) { this.setTimeVal('whTuS', this.f.whTuS, v); }
    },
    cWhTuE: {
      get() { return this.getTimeStr(this.f.whTuE); },
      set(v) { this.setTimeVal('whTuE', this.f.whTuE, v); }
    },
    cWhWeS: {
      get() { return this.getTimeStr(this.f.whWeS); },
      set(v) { this.setTimeVal('whWeS', this.f.whWeS, v); }
    },
    cWhWeE: {
      get() { return this.getTimeStr(this.f.whWeE); },
      set(v) { this.setTimeVal('whWeE', this.f.whWeE, v); }
    },
    cWhThS: {
      get() { return this.getTimeStr(this.f.whThS); },
      set(v) { this.setTimeVal('whThS', this.f.whThS, v); }
    },
    cWhThE: {
      get() { return this.getTimeStr(this.f.whThE); },
      set(v) { this.setTimeVal('whThE', this.f.whThE, v); }
    },
    cWhFrS: {
      get() { return this.getTimeStr(this.f.whFrS); },
      set(v) { this.setTimeVal('whFrS', this.f.whFrS, v); }
    },
    cWhFrE: {
      get() { return this.getTimeStr(this.f.whFrE); },
      set(v) { this.setTimeVal('whFrE', this.f.whFrE, v); }
    },
    cWhSaS: {
      get() { return this.getTimeStr(this.f.whSaS); },
      set(v) { this.setTimeVal('whSaS', this.f.whSaS, v); }
    },
    cWhSaE: {
      get() { return this.getTimeStr(this.f.whSaE); },
      set(v) { this.setTimeVal('whSaE', this.f.whSaE, v); }
    },
    cWhSuS: {
      get() { return this.getTimeStr(this.f.whSuS); },
      set(v) { this.setTimeVal('whSuS', this.f.whSuS, v); }
    },
    cWhSuE: {
      get() { return this.getTimeStr(this.f.whSuE); },
      set(v) { this.setTimeVal('whSuE', this.f.whSuE, v); }
    },
  },
  watch: {
    onlineData: function(data) {
      this.f = { ...data };
    }
  },
  created() {
    // maybe instead of getting again, share with AccountLogin (via timestamp)
    REST.get('/customers/' + this.$store.getters.uuid + '/location')
        .then(resp => {
          this.$store.commit('setLocation', resp.d.data);
        }).catch(e => {
          this.$store.commit('showWarn',e.message)
        })
  },
  methods: {
    getAddrStatus(state) {
      this.isAddrSuccess = state;
    },
    valTxt(txt, minLen, maxLen) {
      if (txt == null) return null;
      if (typeof txt != 'string') return false;
      return (txt.length >= minLen && txt.length <= maxLen) ? true : false;
    },
    valFloat(param) {
      // problem here: wrong input may convert to empty string (which is ok for reset to NULL > rely on browser)
      // workaround: set type="text" and set prop=number and rely on proper parsing
      if (param === null || param === '') return null;
      return !isNaN(parseFloat(param));
    },
    isWrongDay(fi, se, th) {
      if (fi == null || se == null || th == null) return null;
      let frst = new Date('2010-01-03T'+fi+':00Z');
      let scnd = new Date('2010-01-03T'+se+':00Z');
      let thrd = new Date('2010-01-03T'+th+':00Z');
      return frst >= scnd && scnd > thrd;
    },
    isTooLong(fi, se) {
      if (fi == null || se == null) return null;
      let frst = new Date('2010-01-03T'+fi+':00Z');
      let scnd = new Date('2010-01-03T'+se+':00Z');
      return (scnd.getTime() - frst.getTime())/60000 > 1440; // or 1500 for leapday?
    },
    isMidNight(fi, se) {
      if (fi == null || se == null) return null;
      let frst = new Date('2010-01-03T'+fi+':00Z');
      let scnd = new Date('2010-01-03T'+se+':00Z');
      return frst >= scnd;
    },
    isEmpty(fi, se) {
      return (fi == null && se != null) || (fi != null && se == null);
    },
    valTimeComboS(currS, currE, prevNumS, prevNumE) {
      if (currS == null) return null; // nothing set
      if (this.isWrongDay(prevNumS, prevNumE, currS) === true) return false;
      if (this.isTooLong(currS, currE) === true) return false;
      if (this.isEmpty(currS, currE) === true) return false;
      return this.valTimeNum(currS);
    },
    valTimeComboE(currE, currS) {
      if (currE == null) return null; // nothing set
      if (this.isEmpty(currS, currE) === true) return false;
      return this.valTimeNum(currE);
    },
    valTimeNum(num) {
      if (num == null || num === '') return null;
      let frst = new Date('2010-01-03T'+num+':00Z');
      return frst === frst; // if invalid: frst=NaN
    },
    getTimeStr(f) {
      if (f == null) return null;
      return f;
    },
    setTimeVal(str, oldVal, newVal) {
      if (newVal == null) {
        this.f[str] = null;
        return;
      }
      this.f[str] = newVal;
    },
    onSubmit() {
      let data = { ...this.f};
      REST.put('/customers/' + this.$store.getters.uuid + '/location', data)
          .then(resp => {
            this.$store.commit('showSuccess', resp.d.message);
            this.$store.commit('setLocation', data); // triggers again computed/watch props
          }).catch(e => {
            this.$store.commit('showWarn', e.message);
          })
    },
    onReset() {
      this.f = { ...this.$store.state.oLocation };
    },
    onMapSelect(v) {
      this.f.geoLat = parseFloat(v[0].toFixed(6));
      this.f.geoLong = parseFloat(v[1].toFixed(6));
    },
    commaRepl(v) {
      return v.replace(',','.').replace(/[^-.0-9]+/, '');
    },
  },
  components: {
    TimePicker,
    ModalMap,
    Icon,
    BIconAt,
    BIconPhone,
    BIconClock,
    AddressContent,
  }
}
</script>
