<template>
  <b-modal :id="id" v-model="show" :title="title" :size="size" @close="onReset()" @shown="onShown()" lazy>
    <b-container class="text-center mt-3">
      <b-img v-if="!hidePic" class="mb-4 rounded" src="@/assets/logo.png" :alt="$t('nav.brand')" width="150" />
      <slot />
    </b-container>
    <template #modal-footer>
      <b-form-row class="justify-content-end">
        <b-col v-if="!hideSecondary" class="col-auto mr-2 mr-sm-3 px-0">
          <b-button type="button" variant="primary"
              :id="[id + 'Btn0']"
              @click="onReset()">
            <Icon v-if="btnIconReset!==null" :type="btnIconReset" /> {{ btnTextReset }}
          </b-button>
        </b-col>
        <b-col class="col-auto px-0">
          <b-button type="button" :variant="btnVariantSubmit"
              :id="[id + 'Btn1']" :disabled="btnDisableSubmit"
              @click="onSubmit()">
            <Icon v-if="btnIconSubmit!==null" :type="btnIconSubmit" /> {{ btnTextSubmit }}
          </b-button>
        </b-col>
      </b-form-row>
    </template>
  </b-modal>
</template>

<script>
import Icon from '@/components/Icon';

export default {
  data() {
    return {
      show: false
    }
  },
  methods: {
    onReset() {
      this.show = false;
      this.$emit('reset');
    },
    onSubmit() {
      this.show = false;
      this.$emit('submit');
    },
    onShown() {
      this.$emit('shown');
    },
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    btnTextReset: {
      type: String,
      default: 'Yes',
    },
    btnTextSubmit: {
      type: String,
      default: 'No',
    },
    btnVariantSubmit: {
      type: String,
      default: 'danger',
    },
    btnDisableSubmit: {
      type: Boolean,
      default: false,
    },
    btnIconReset: {
      type: String,
      default: null,
    },
    btnIconSubmit: {
      type: String,
      default: null,
    },
    hidePic: {
      type: Boolean,
      default: false,
    },
    hideSecondary: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  components: {
    Icon,
  },
}
</script>
