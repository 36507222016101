<template>
  <div>
    <b-row>
      <b-form-group :label="$t('addr.lstl')" :label-for="idPrefix+`Street`" class="text-left col-8">
        <b-input type="text" :id="idPrefix+`Street`" :form="form" :value="aStreet" @input="$emit('aStreet',$event)" maxlength="75" :state="valTxt(aStreet, 2, 75)" trim required :disabled="disabled" />
        <b-form-invalid-feedback :state="valTxt(aStreet, 2, 75)">
          {{ $t('addr.ff') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('addr.lnol')" :label-for="idPrefix+`AddrNo`" class="text-left col-4">
        <b-input type="text" :id="idPrefix+`AddrNo`" :form="form" :value="aNumber" @input="$emit('aNumber',$event)" maxlength="10" :state="aNumber==''?null:valTxt(aNumber, 0, 10)" trim required :disabled="disabled" />
        <b-form-invalid-feedback :state="aNumber==''?null:valTxt(aNumber, 0, 10)">
          {{ $t('addr.ff') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-row>
    <b-row>
      <b-form-group :label="$t('addr.lctl')" :label-for="idPrefix+`City`" class="text-left col-8">
        <b-input type="text" :id="idPrefix+`City`" :form="form" :value="aCity" @input="$emit('aCity',$event)" maxlength="75" :state="valTxt(aCity, 2, 75)" trim required :disabled="disabled" />
        <b-form-invalid-feedback :state="valTxt(aCity, 2, 75)">
          {{ $t('addr.ff') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('addr.lzpl')" :label-for="idPrefix+`Zip`" class="text-left col-4">
        <b-input type="text" :id="idPrefix+`Zip`" :value="aZip" @input="$emit('aZip',$event)" maxlength="10" :state="valZip" trim required :disabled="disabled" />
        <b-form-invalid-feedback :state="valZip">
          {{ $t('addr.ff') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-row>
    <b-row>
      <b-form-group :label="$t('addr.lcnl')" :label-for="idPrefix+`Country`" class="text-left col-6">
        <b-form-select :id="idPrefix+`Country`" :form="form" :value="aCountryId" @input="$emit('aCountryId',$event)" :options="optCountries" :state="valCountry" required :disabled="disabled">
          <template #first>
            <option :value="null">- {{ $t('dial.selopt') }} -</option>
          </template>
        </b-form-select>
        <b-form-invalid-feedback :state="valCountry">
          {{ $t('addr.ff') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('addr.lsal')" :label-for="idPrefix+`State`" class="text-left col-6">
        <b-form-select :id="idPrefix+`State`" :form="form" :value="aStateId" @input="$emit('aStateId',$event)" :options="optStates" :state="valState" :disabled="disabled">
          <template #first>
            <option :value="null">- {{ $t('dial.selopt') }} -</option>
          </template>
        </b-form-select>
        <b-form-invalid-feedback :state="valState">
          {{ $t('addr.ff') }}
        </b-form-invalid-feedback>
      </b-form-group>
    </b-row>
  </div>
</template>

<script>
import cntryList from '@/components/data/Countries';
import stateList from '@/components/data/States';

export default {
  data() {
    return {
    }
  },
  computed: {
    optCountries() {
      return cntryList.map((v,idx) => { return {value: idx+1, text: this.$t('cntry.'+v)}; }, {});
    },
    optStates() {
      return stateList.map((v,idx) => { return {value: idx+1, text: v.t}; }, {}); // for the moment no translation
    },
    valZip() {
      return this.aZip == null ? null : /^(\d){5,5}$/.test(this.aZip);
    },
    valCountry() {
      return this.aCountryId == null || (this.aCountryId > 0 && this.aCountryId <= 2);
    },
    valState() {
      return this.aStateId == null || (this.aStateId > 0 && this.aStateId <= 16);
    },
    val() {
      return (this.aStreet != '' && this.valTxt(this.aNumber, 0, 10) && this.aCity != '' && this.valZip && this.valCountry && this.valState);
    },
  },
  watch: {
    val: {
      handler(n) {
        this.$emit('status', n);
      }
    },
  },
  created() {
  },
  methods: {
    valTxt(txt, minLen, maxLen) {
      if (txt == null) return null;
      if (typeof txt != 'string') return false;
      return (txt.length >= minLen && txt.length <= maxLen) ? true : false;
    },
  },
  props: {
    idPrefix: {
      type: String,
      required: true,
    },
    form: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    // isUser: {
    //   type: Boolean,
    //   required: true,
    // },
    aStreet: {
      type: String,
      default: '',
    },
    aNumber: {
      type: String,
      default: '',
    },
    aCity: {
      type: String,
      default: '',
    },
    aZip: {
      type: String,
      default: '',
    },
    aCountryId: {
      type: Number,
      default: 1,
    },
    aStateId: {
      type: Number,
      default: null,
    },
  },
  components: {
  },
}
</script>
