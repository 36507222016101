<template>
  <b-form-timepicker :ref="`time`+id" :id="id" :state="state" :required="required"
      :value="value" @input="process($event)" @wheel.native="wheel" @keyup.native="btnpress" minutes-step="15"
      :locale="locale" hide-header no-close-button class="textwrapfix"
      :label-selected="$t('dt.tse')" :label-no-time-selected="$t('dt.tns')" :label-hours="$t('dt.th')" :label-minutes="$t('dt.tm')"
      :label-increment="$t('dt.tin')" :label-decrement="$t('dt.tde')"
      :label-am="$t('dt.tam')" :label-pm="$t('dt.tpm')" :label-ampm="$t('dt.tap')" />
</template>

<script>
export default {
  computed: {
    locale() {
      return this.$i18n.locale;
    }
  },
  methods: {
    process(x) {
      if (x == null || x == '') {
        return;
      }
      let o = new Date('2020-01-03T' + this.value + 'Z');
      let n = new Date('2020-01-03T' + x + 'Z');
      if (o.getTime() == n.getTime()) {
        return;
      }
      if (o.getUTCMinutes() == 45 && n.getUTCMinutes() == 0) {
        n = new Date(n.getTime() + 3600000);
      }
      if (o.getUTCMinutes() == 0 && n.getUTCMinutes() == 45) {
        n = new Date(n.getTime() - 3600000);
      }
      this.sendEvent(n);
    },
    sendEvent(d) {
      if (d == null) {
        this.$emit('input', null);
        return;
      }
      if (d != null && isNaN(d.getTime())) {
        return;
      }
      let m = d.getUTCMinutes();
      let h = d.getUTCHours();
      if (m < 10) { m = '0' + m; }
      if (h < 10) { h = '0' + h; }
      let t = h+':'+m;
      if (!this.hideSeconds) {
        t += ':00';
      }
      if (t == this.value) {
        return;
      }
      this.$emit('input', t);
    },
    btnpress(b) {
      let val = 0;
      if (b.keyCode >= 48 && b.keyCode <= 57) {
        val = b.keyCode - 48;
      } else if (b.keyCode >= 96 && b.keyCode <= 105) {
        val = b.keyCode - 96;
      } else if (b.keyCode == 8 || b.keyCode == 46) {
        val = null;
      } else {
        return;
      }

      let offset = this.getType(b.srcElement);
      if (offset == null) { return; }

      b.preventDefault();
      if (val == null) {
        this.sendEvent(null);
        return;
      }

      if (offset == 3600000) {
        let n = 0;
        if (this.value != null) {
          n = parseInt(this.value.substr(0, 2));
          n = n*10+val;
          if (n > 23) { return; }
          n = '' + n + this.value.substr(2, 3);
        } else {
          n = '0' + val + ':00';
        }
        this.sendEvent(new Date('2020-01-03T' + n + ':00Z'));
      }
    },
    wheel(x) {
      const isUp = (x.deltaY <= 0) ? 1 : -1;
      let s = '11:00:00';
      if (this.value !== null) { s = this.value; }
      let d = new Date('2020-01-03T' + s + 'Z');

      let offset = this.getType(x.srcElement);
      if (offset == null) { return; }

      x.preventDefault();
      d = new Date(d.getTime()+ isUp*offset);
      this.sendEvent(d);
    },
    getType(e) {
      while (e != null) {
        if (e.classList.contains('b-time-hours')) {
          return 3600000;
        } else if (e.classList.contains('b-time-minutes')) {
          return 900000;
        } else {
          e = e.parentElement;
          if (e == null || e.classList.contains('b-form-timepicker')) {
            return null;
          }
        }
      }
    }
  },
  props: {
    state: {
      type: Boolean,
      default: null,
    },
    id: {
      type: [Number, String],
      required: true,
    },
    required: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: null,
    },
    formid: {
      type: String,
      required: true,
    },
    format: {
      type: Object,
      default() {
        return { hour: 'numeric', minute: 'numeric' };
      }
    },
    hideSeconds: {
      type: Boolean,
      default: false,
    },
  }
}
</script>

<style scoped>
.textwrapfix >>> label.form-control {
  white-space: nowrap !important;
}
</style>