const tz = [
  {t: 'Midway Island, Samoa', v: 'Pacific/Midway', o: -11},
  {t: 'Hawaii', v: 'Pacific/Honolulu', o: -10},
  {t: 'Alaska', v: 'US/Alaska', o: -9},
  {t: 'Pacific Time (US & Canada)', v: 'America/Los_Angeles', o: -8},
  {t: 'Tijuana, Baja California', v: 'America/Tijuana', o: -8},
  {t: 'Arizona', v: 'US/Arizona', o: -7},
  {t: 'Chihuahua, La Paz, Mazatlan', v: 'America/Chihuahua', o: -7},
  {t: 'Mountain Time (US & Canada)', v: 'US/Mountain', o: -7},
  {t: 'Central America', v: 'America/Managua', o: -6},
  {t: 'Central Time (US & Canada)', v: 'US/Central', o: -6},
  {t: 'Guadalajara, Mexico City', v: 'America/Mexico_City', o: -6},
  {t: 'Saskatchewan', v: 'Canada/Saskatchewan', o: -6},
  {t: 'Bogota, Lima, Quito, Rio Branco', v: 'America/Bogota', o: -5},
  {t: 'Eastern Time (US & Canada)', v: 'US/Eastern', o: -5},
  {t: 'Indiana (East)', v: 'US/East-Indiana', o: -5},
  {t: 'Atlantic Time (Canada)', v: 'Canada/Atlantic', o: -4},
  {t: 'Caracas, La Paz', v: 'America/Caracas', o: -4},
  {t: 'Manaus', v: 'America/Manaus', o: -4},
  {t: 'Santiago', v: 'America/Santiago', o: -4},
  {t: 'Newfoundland', v: 'Canada/Newfoundland', o: -3.5},
  {t: 'Brasilia, Sao Paulo', v: 'America/Sao_Paulo', o: -3},
  {t: 'Buenos Aires, Georgetown', v: 'America/Argentina/Buenos_Aires', o: -3},
  {t: 'Greenland', v: 'America/Godthab', o: -3},
  {t: 'Montevideo', v: 'America/Montevideo', o: -3},
  {t: 'Mid-Atlantic', v: 'America/Noronha', o: -2},
  {t: 'Cape Verde Is.', v: 'Atlantic/Cape_Verde', o: -1},
  {t: 'Azores', v: 'Atlantic/Azores', o: -1},
  {t: 'Casablanca, Monrovia', v: 'Africa/Casablanca', o: 0},
  {t: 'London, Dublin, Lisbon', v: 'Europe/London', o: 0},
  {t: 'Berlin, Rome, Stockholm', v: 'Europe/Berlin', o: 1},
  {t: 'Belgrade, Budapest, Prague', v: 'Europe/Belgrade', o: 1},
  {t: 'Brussels, Madrid, Paris', v: 'Europe/Brussels', o: 1},
  {t: 'Sarajevo, Skopje, Warsaw', v: 'Europe/Sarajevo', o: 1},
  {t: 'West Central Africa', v: 'Africa/Lagos', o: 1},
  {t: 'Amman', v: 'Asia/Amman', o: 2},
  {t: 'Athens, Bucharest, Istanbul', v: 'Europe/Athens', o: 2},
  {t: 'Beirut', v: 'Asia/Beirut', o: 2},
  {t: 'Cairo', v: 'Africa/Cairo', o: 2},
  {t: 'Harare, Pretoria', v: 'Africa/Harare', o: 2},
  {t: 'Helsinki, Kyiv, Riga, Sofia, Vilnius', v: 'Europe/Helsinki', o: 2},
  {t: 'Jerusalem', v: 'Asia/Jerusalem', o: 2},
  {t: 'Minsk', v: 'Europe/Minsk', o: 2},
  {t: 'Windhoek', v: 'Africa/Windhoek', o: 2},
  {t: 'Kuwait, Riyadh, Baghdad', v: 'Asia/Kuwait', o: 3},
  {t: 'Moscow, St. Petersburg, Volgograd', v: 'Europe/Moscow', o: 3},
  {t: 'Nairobi', v: 'Africa/Nairobi', o: 3},
  {t: 'Tbilisi', v: 'Asia/Tbilisi', o: 3},
  {t: 'Tehran', v: 'Asia/Tehran', o: 3.5},
  {t: 'Abu Dhabi, Muscat', v: 'Asia/Muscat', o: 4},
  {t: 'Baku', v: 'Asia/Baku', o: 4},
  {t: 'Yerevan', v: 'Asia/Yerevan', o: 4},
  {t: 'Kabul', v: 'Asia/Kabul', o: 4.5},
  {t: 'Yekaterinburg', v: 'Asia/Yekaterinburg', o: 5},
  {t: 'Islamabad, Karachi, Tashkent', v: 'Asia/Karachi', o: 5},
  {t: 'Chennai, Mumbai, New Delhi', v: 'Asia/Calcutta', o: 5.5},
  {t: 'Sri Jayawardenapura', v: 'Asia/Jayapura', o: 5.5},
  {t: 'Kathmandu', v: 'Asia/Katmandu', o: 5.75},
  {t: 'Almaty, Novosibirsk', v: 'Asia/Almaty', o: 6},
  {t: 'Astana, Dhaka', v: 'Asia/Dhaka', o: 6},
  {t: 'Yangon (Rangoon)', v: 'Asia/Rangoon', o: 6.5},
  {t: 'Bangkok, Hanoi, Jakarta', v: 'Asia/Bangkok', o: 7},
  {t: 'Krasnoyarsk', v: 'Asia/Krasnoyarsk', o: 7},
  {t: 'Beijing, Chongqing, Hong Kong', v: 'Asia/Hong_Kong', o: 8},
  {t: 'Kuala Lumpur, Singapore', v: 'Asia/Kuala_Lumpur', o: 8},
  {t: 'Irkutsk, Ulaan Bataar', v: 'Asia/Irkutsk', o: 8},
  {t: 'Perth', v: 'Australia/Perth', o: 8},
  {t: 'Taipei', v: 'Asia/Taipei', o: 8},
  {t: 'Osaka, Sapporo, Tokyo', v: 'Asia/Tokyo', o: 9},
  {t: 'Seoul', v: 'Asia/Seoul', o: 9},
  {t: 'Yakutsk', v: 'Asia/Yakutsk', o: 9},
  {t: 'Adelaide', v: 'Australia/Adelaide', o: 9.5},
  {t: 'Darwin', v: 'Australia/Darwin', o: 9.5},
  {t: 'Brisbane', v: 'Australia/Brisbane', o: 10},
  {t: 'Canberra, Melbourne, Sydney', v: 'Australia/Canberra', o: 10},
  {t: 'Hobart', v: 'Australia/Hobart', o: 10},
  {t: 'Guam, Port Moresby', v: 'Pacific/Guam', o: 10},
  {t: 'Vladivostok', v: 'Asia/Vladivostok', o: 10},
  {t: 'Magadan, New Caledonia', v: 'Asia/Magadan', o: 11},
  {t: 'Auckland, Wellington', v: 'Pacific/Auckland', o: 12},
  {t: 'Fiji, Kamchatka, Marshall Is.', v: 'Pacific/Fiji', o: 12},
  {t: 'Nuku\'alofa', v: 'Pacific/Tongatapu', o: 13}
];

export default tz;
